/* eslint-disable  */

import {
  MeetingSessionStatus,
  MeetingSessionStatusCode,
} from 'amazon-chime-sdk-js';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import MeetingStatus from '../enums/MeetingStatus';
// import common from '../constants/common.json';
import routes from '../constants/routes.json';

type Props = {
  children: ReactNode;
};

export default function MeetingStatusProvider(props: Props) {
  const MeetingStatusContext = getMeetingStatusContext();
  const { children } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const [meetingStatus, setMeetingStatus] = useState<{
    meetingStatus: MeetingStatus;
    errorMessage?: string;
  }>({
    meetingStatus: MeetingStatus.Loading,
  });
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const location = useLocation();
  let locationState = location?.state || null;
  const audioElement = useRef(null);

  useEffect(() => {
    // locationState =  
    const localDataString: string | null = localStorage.getItem('meetingdata');
    const localData = localDataString ? JSON.parse(localDataString).state : null;

    if (locationState || localData) {
      const {
        meetingName,
        meetingID,
        id,
        batchId,
        userName,
        mode,
        userID,
        startTime,
        endTime,
        isRecording,
        meetingType,
      }: any = locationState || localData;
      // console.log(
      //   '🙋🙋🙋🙋',
      //   meetingName,
      //   meetingID,
      //   id,
      //   batchId,
      //   userName,
      //   mode,
      //   userID,
      //   startTime,
      //   endTime,
      //   isRecording
      // );
      const start = async () => {
        try {
          await chime?.createRoom(
            meetingName,
            meetingID,
            id,
            batchId,
            userName,
            userID,
            startTime,
            endTime,
            isRecording,
            meetingType,
            mode === 'mp' ? 'teacher' : 'student',
            query.get('optionalFeature')
          );

          setMeetingStatus({
            meetingStatus: MeetingStatus.Succeeded,
          });

          chime?.audioVideo?.addObserver({
            audioVideoDidStop: async (
              sessionStatus: MeetingSessionStatus
            ): Promise<void> => {
              console.log('sessionStatus', sessionStatus.statusCode(),'=======================');
              console.log('MeetingSessionStatusCode.MeetingEnded', MeetingSessionStatusCode.MeetingEnded);

              if(sessionStatus.statusCode() == 16) {
                setTimeout(() =>window.location.reload(),10000) 
              }

              if (
                sessionStatus.statusCode() ===
                MeetingSessionStatusCode.MeetingEnded
              ) { 
                   if (mode === 'mp') {
                      await chime?.leaveRoom(true);
                      navigate(`${routes.MAIN}?id=${id}`);
                      // window.location.href = `${common.domain}complete?id=${id}`;
                    } else {
                      await chime?.leaveRoom(false);
                      navigate(routes.MAIN);
                      // window.location.href = `${common.domain}complete`;
                    }
              }
              // if (
              //   sessionStatus.statusCode() ===
              //   MeetingSessionStatusCode.AudioCallEnded
              // ) {
              //   // navigate('/');
              //   if (mode === 'mp') {
              //     await chime?.leaveRoom(true);
              //     navigate(`${routes.MAIN}?id=${id}`);
              //     // window.location.href = `${common.domain}complete?id=${id}`;
              //   } else {
              //     await chime?.leaveRoom(false);
              //     navigate(routes.MAIN);
              //     // window.location.href = `${common.domain}complete`;
              //   }
              // }
            },
          });

          await chime?.joinRoom(audioElement.current);
        } catch (error: any) {
          // eslint-disable-next-line
          console.error(error,'====================Errorrrrrr===================================',error.message);
          setMeetingStatus({
            meetingStatus: MeetingStatus.Failed,
            errorMessage: error.message,
          });
        }
      };
      start();
    } else {
      // window.location.href = `${common.callbackUrl}complete`;
      window.location.reload();
    }
  }, []);

  return (
    <MeetingStatusContext.Provider value={meetingStatus}>
      {/* eslint-disable-next-line */}
      <audio id="audioElement" ref={audioElement} style={{ display: 'none' }} />
      {children}
    </MeetingStatusContext.Provider>
  );
}
